// AutocompleteCities.tsx
import { ApiRequest } from 'GlobalFunctions';
import { useEffect, useState, useCallback } from 'react';
import { debounce } from 'lodash';

export default function AutocompleteCities(props: any) {

    const { formValues, setFormValues, pc_city, pc_state } = props;
    const [cities, setCities] = useState([]);
    const [inputPosition, setInputPosition] = useState({ top: 0, left: 0, width: 0 });
    const [isVisible, setIsVisible] = useState(false);

    /*=========================================================
    GET CITIES
    =========================================================*/
    const getCities = async (keyword: any) => {
        let region_id;
        if (pc_state === 's-state') {
            region_id = formValues.s_state;
        } else {
            region_id = formValues.state;
        }
        if (!region_id) {
            setCities([]);
            return;
        }
        await ApiRequest({
            url: '/countries/getCities',
            method: 'GET',
            query: {
                region_id,
                keyword
            },
            setResponse: (response: any) => {
                setCities(response);
            }
        });
    }

    const debouncedGetCities = useCallback(debounce(getCities, 300), [formValues[pc_state.replace('-', '_')]]);

    /*=========================================================
    USE EFFECT TO ATTACH EVENT LISTENERS
    =========================================================*/
    useEffect(() => {
        const element = document.querySelector(`[pc="${pc_city}"]`) as HTMLInputElement;
        if (element) {
            element.setAttribute('autocomplete', 'new-password');
            const updatePosition = () => {
                const rect = element.getBoundingClientRect();
                setInputPosition({ 
                    top: rect.bottom + window.scrollY, 
                    left: rect.left + window.scrollX, 
                    width: rect.width 
                });
            };

            const handleInput = (e: any) => {
                const value = e.target.value;
                debouncedGetCities(value);
            };

            const handleFocus = () => {
                updatePosition();
                setIsVisible(true);
                debouncedGetCities(''); // Trigger search with empty keyword on focus
            };

            const handleBlur = () => {
                setTimeout(() => {
                    setIsVisible(false);
                }, 200); // Retraso para permitir clic en la sugerencia
            };

            element.addEventListener("input", handleInput);
            element.addEventListener("focus", handleFocus);
            element.addEventListener("blur", handleBlur);

            // Actualizar posición en cambio de tamaño de ventana
            window.addEventListener('resize', updatePosition);

            return () => {
                element.removeEventListener("input", handleInput);
                element.removeEventListener("focus", handleFocus);
                element.removeEventListener("blur", handleBlur);
                window.removeEventListener('resize', updatePosition);
            };
        }
    }, [debouncedGetCities]);

    /*=========================================================
    RENDER
    =========================================================*/
    return (
        isVisible ? (
            <div
                className='cities_list'
                style={{ 
                    position: 'absolute', 
                    top: `${inputPosition.top}px`, 
                    left: `${inputPosition.left}px`, 
                    width: `${inputPosition.width}px`, 
                    backgroundColor: 'white', 
                    border: '1px solid #ccc', 
                    zIndex: 1000 
                }}
            >
                {cities.map((city: any) => (
                    <div
                        key={city.city_id}
                        className='autocomplete_item'
                        data-city-id={city.city_id}
                        data-city-name={city.city_name}
                        onMouseDown={() => {
                            const city_id = city.city_id;
                            const city_name = city.city_name;
                            const element = document.querySelector(`[pc="${pc_city}"]`) as HTMLInputElement;
                            if (element) {
                                element.value = city_name || '';
                                const event = new Event('input', { bubbles: true });
                                element.dispatchEvent(event);
                            }
                            setFormValues((prevFormValues: any) => ({
                                ...prevFormValues,
                                [pc_city.replace('-', '_')]: city_name,
                                [pc_city.replace('-', '_') + '_id']: city_id
                            }));
                            setIsVisible(false);
                        }}
                    >
                        {city.city_name}
                    </div>
                ))}
            </div>
        ) : null
    );
}
