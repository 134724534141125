import { ApiRequest, ShowLoader } from "GlobalFunctions";
import {getProductQuantity} from "components/Bag/Bag";

/*=============================
GET ORDER DETAILS
=============================*/
export default async function getOrderDetails(params: any) {

    /*=============================
    CHECK IF ORDER ID EXISTS
    ==============================*/
    const order_id = localStorage.getItem('order_id');
    if (!order_id) {
        return;
    }

    // ShowLoader(true);

    const {
        page,
        callback = null
    } = params;

    let cart = null;

    await ApiRequest({
        url: "/orders/details",
        method: "get",
        query: {
            order_id,
            page
        },
        setResponse: async (response: any) => {
            if (response.status !== 'error') {
                cart = response;
                // await setSummary(cart);
                await renderCartProducts(cart.products);
            }
            
        },
    });

    if (callback) {
        await callback(cart)
    }

    ShowLoader(false);
    
    return cart;
}


/*=============================
SET SUMMARY 
=============================*/
// const setSummary = async (cart: any) => {
//     const subtotalElement = document.querySelector('[pc="summary-subtotal"]');
//     if (subtotalElement) {
//         subtotalElement.innerHTML = '$' + Number(cart.subtotal_price).toFixed(2).toLocaleString();
//     }

//     const taxElement = document.querySelector('[pc="summary-tax"]');
//     if (taxElement) {
//         taxElement.innerHTML = '$' + Number(cart.total_tax).toFixed(2).toLocaleString();
//     }

//     const deliveryElement = document.querySelector('[pc="summary-delivery"]');
//     if (deliveryElement) {
//         deliveryElement.innerHTML = '$' + Number(cart.shipping_price).toFixed(2).toLocaleString();
//     }

//     const discountElement = document.querySelector('[pc="summary-discount-value"]');
//     if (discountElement && cart.discount > 0) {
//         discountElement.innerHTML = '$' + Number(cart.discount).toFixed(2).toLocaleString();

//         const coupon_code = cart.coupon_code;
//         const summary_discount_label = document.querySelector('[pc="summary-discount-label"]');
//         if (summary_discount_label) {
//             // Eliminar el contenido previo si ya existía
//             summary_discount_label.innerHTML = `
//                 Discount <span 
//                 class='coupon-chip'
//                 >${coupon_code} 
//                 <a
//                 class='remove-coupon'
//                 href="#" style="cursor: pointer; margin-left: 5px;">
//                 <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 48 48" style="enable-background:new 0 0 48 48;" xml:space="preserve">
//                 <path d="M45.8,0c-0.6,0-1.1,0.2-1.5,0.6L24,21L3.6,0.7C3.2,0.2,2.7,0,2.1,0C1,0,0,1,0,2.1c0,0.6,0.2,1.1,0.6,1.5L21,24L0.7,44.4  c-0.8,0.8-0.9,2.1-0.1,3s2.1,0.9,3,0.1c0,0,0,0,0.1-0.1L24,27l20.4,20.4c0.8,0.8,2.1,0.9,3,0.1s0.9-2.1,0.1-3c0,0,0,0-0.1-0.1L27,24  L47.3,3.6c0.8-0.8,0.9-2.1,0.1-3C47,0.2,46.4,0,45.8,0z"/>
//                 </svg>
//                 </a></span>

                
//             `;

//             // Agregar el listener al botón "x"
//             const removeLink = summary_discount_label.querySelector('a');
//             if (removeLink) {
//                 removeLink.addEventListener('click', (e) => {
//                     e.preventDefault();
//                     removeCoupon(coupon_code);
//                 });
//             }
//         }
//     }

//     const totalElement = document.querySelector('[pc="summary-total"]');
//     if (totalElement) {
//         totalElement.innerHTML = '$' + Number(cart.total_price).toFixed(2).toLocaleString();
//     }
// };




const removeCoupon = async (coupon_code: string) => {
    ShowLoader(true);
    ApiRequest({
        url: "/orders/removeCoupon",
        method: "post",
        body: {
            order_id: localStorage.getItem('order_id')
        },
        setResponse: async (response: any) => {
            if (response.status === 'success') {
                window.location.reload();
            }
        },
    });
    ShowLoader(false);
}





/*=============================
RENDER CART PRODUCTS
=============================*/
const renderCartProducts = async (products: any) => {
    
    const cartProductsContainer = document.querySelector('[pc="product-list"]');
    if (!cartProductsContainer) return;

    const productModel = document.querySelector('[pc="product-item"]');
    if (!productModel) return;
    const productModelCloneForRemoval = productModel.cloneNode(true) as HTMLElement;

    cartProductsContainer.innerHTML = '';


    /* MAP PRODUCTS
    ==============================*/
    products.forEach((product: any) => {
        const productClone = productModelCloneForRemoval.cloneNode(true) as HTMLElement;

        productClone.querySelector('[pc="product-name"]')!.innerHTML = product.product_name;
        productClone.querySelector('[pc="product-price"]')!.innerHTML = '$' + Number(product.product_price).toFixed(2).toLocaleString();
        
        /* QUANTITY SELECT
        ==============================*/
        const quantityOptions = Array.from({ length: 10 }, (_, i) => i + 1).map(number => (
            `<option value="${number}">${number}</option>`
        )).join('');

        const quantitySelectElement = productClone.querySelector('[pc="product-quantity"]');

        if (quantitySelectElement) {

            const classes = quantitySelectElement?.getAttribute('class');

            if (quantitySelectElement.tagName.toLowerCase() !== 'select') {
                const quantitySelect = `<select pc="product-quantity" class="${classes}" name="product-quantity">
                    ${quantityOptions}
                </select>`;
                quantitySelectElement.outerHTML = quantitySelect;
            }

            const updatedQuantitySelectElement = productClone.querySelector('[pc="product-quantity"]') as HTMLSelectElement;
            updatedQuantitySelectElement.innerHTML = quantityOptions;
            updatedQuantitySelectElement.value = String(product.quantity);
            updatedQuantitySelectElement.addEventListener('change', async () => {
                await updateProductQuantity(product.order_product_id, updatedQuantitySelectElement.value);
            });
        } 


        /* IMAGE 
        ============================== */
        const productImageElement = productClone.querySelector('[pc="product-image"]') as HTMLImageElement;

        if (productImageElement) {
            productClone.querySelector('[pc="product-image"]')!.setAttribute('src', product.   product_image);
            productClone.querySelector('[pc="product-image"]')!.removeAttribute('srcset');
        }

        /* CUSTOM INPUTS
        ============================== */
        const custom_inputs = product.custom_inputs
            .map((attr: any) => `${attr.name}: ${attr.value}`)
            .join('<br>');

        const customInputsElement = productClone.querySelector('[pc="product-custom"]')

        if (customInputsElement) {
            customInputsElement.innerHTML = custom_inputs;
        }

        

        /* REMOVE BUTTON
        ============================== */
        const removeButtonElement = productClone.querySelector('[pc="remove-product"]') as HTMLElement;

        if (removeButtonElement) {
            removeButtonElement.addEventListener('click', async () => {
                await removeProduct(product.order_product_id);
            });
        }
        



        /* CHILD PRODUCT
        ============================== */
        if (product.parent_product_id > 0) {
            productClone.classList.add('product-child');
        }
        
        
        cartProductsContainer.appendChild(productClone);
    });
};




/*=============================
UPDATE PRODUCT QUANTITY
=============================*/
export async function updateProductQuantity(order_product_id: string, quantity: string) {
    
    ShowLoader(true);
    ApiRequest({
        url: "/orders/updateProductQuantity",
        method: "post",
        body: {
            order_product_id,
            quantity
        },
        setResponse: async (response: any) => {
            if (response.status === 'success') {
                await getOrderDetails("cart");
            }
        },
    });
    ShowLoader(false);
}


/*=============================
REMOVE PRODUCT
=============================*/
export async function removeProduct(order_product_id: string) {
        
    ShowLoader(true);
    ApiRequest({
        url: "/orders/removeProduct",
        method: "post",
        body: {
            order_product_id,
            order_id: localStorage.getItem('order_id')
        },
        setResponse: async (response: any) => {
            if (response.status === 'success') {
                getProductQuantity();
                await getOrderDetails("cart");
            }
        },
    });
    ShowLoader(false);
}