import { Button, TextField } from "@mui/material";
import { ApiRequest } from "GlobalFunctions";
import { useEffect, useState } from "react";
import { CiWarning } from "react-icons/ci";
import { TfiLock } from "react-icons/tfi";
import { CiCreditCard1 } from "react-icons/ci";
import { BsCalendar2Check } from "react-icons/bs";
import { GoPerson } from "react-icons/go";


export default function POForm(props: any) {

    const { 
        payment_method,
        setPaymentStatus,
        enqueueSnackbar,
        ShowLoader
} = props;

    const [formValues, setFormValues] = useState({
        po_number: "",
    });


    const submitPayment = async () => {

        ShowLoader(true);
        await ApiRequest({
            url: "/payments/defaultCheckout",
            method: "post",
            body: {
                order_id: localStorage.getItem("order_id"),
                payment_method: payment_method,
                po_number: formValues.po_number,
            },
            setResponse: (response: any) => {
                console.log("res",response);
                if(response.status === "success") {
                    setPaymentStatus('success', "Order placed successfully");
                }
                else {
                    setPaymentStatus('error', "Order placement failed");  
                }
            }
        });
        ShowLoader(false);
        
    }

    const [cssClassList, setCssClassList] = useState("w-input");
    const [buttonClassList, setButtonClassList] = useState("w-button");

    useEffect(() => {
        const inputElement = document.querySelector('[pc="fname"]');

        if (inputElement) {
            const classList = inputElement.classList.value;
            setCssClassList(classList);
        }

        const buttonElement = document.querySelector('[pc-step="accordion-next"]');
        if (buttonElement) {
            let classList = buttonElement.classList.value;
            classList = classList.replace("is-disabled", "").trim(); // Eliminar "is-disabled"
            setButtonClassList(classList);
        }

    } ,[]);



    return (
        <div className="form_wrapper">
            <div className="input_group">
                <TextField 
                    placeholder="Purchase Order"
                    value={formValues.po_number}
                    onChange={(e) => setFormValues({ ...formValues, po_number: e.target.value })}
                    className={cssClassList}
                                    
                />
                    <div className="payment_button_container">
                        <button 
                            className={buttonClassList}
                            onClick={submitPayment}
                            style={{width: "100%"}}
                        >
                            Place Order
                        </button>

                    </div>

           
                    

                
            </div>
        </div>
    );
}
