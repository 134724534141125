export default async function getDataAttributes (selectors: any) {
  return selectors.reduce((data: any, selector: any) => {
    const attributeName = selector.attribute.replace(/-/g, '_'); // Convertir guiones medios a guiones bajos
    const elements = document.querySelectorAll(`[pc="${selector.attribute}"]`);
    if (selector.target === 'array') {
      data[attributeName] = Array.from(elements).map((element: any) => ({
        name: element.name || null,
        value: (element instanceof HTMLInputElement || element instanceof HTMLTextAreaElement ? element.value : element.textContent),
      }));
    } else if (elements.length > 0) {
      const element: any = elements[0];
      if (element instanceof HTMLSelectElement) {
        data[attributeName] = element.value; // Obtener solo la opción seleccionada
      } else {
        let value = selector.target ? element.getAttribute(selector.target) : (element instanceof HTMLInputElement || element instanceof HTMLTextAreaElement ? element.value : element.textContent);
        if (selector.attribute === 'product-price') {
          value = element.textContent?.replace(/[^0-9.]/g, ''); // Obtener solo el número y el punto decimal
        }
        data[attributeName] = value;
      }
    } else {
      data[attributeName] = null;
    }
    return data;
  }, {});
}