import { ApiRequest, ShowLoader } from "GlobalFunctions";
import getOrderDetails from "./getOrderDetails";

export default function Coupons(props: any) {
    const { 
        summary,
        page,
        getSummary 
    } = props;

    console.log(summary)

    if (summary && Object.keys(summary).length > 0) {
        console.log(summary)
        const coupon_id = Number(summary.coupon_id);
        hideCouponContainer(coupon_id);
    }

    configCoupon(page, getSummary);
    return null
}

/*=============================
SET SUMMARY
=============================*/
const configCoupon = async (page: any, getSummary: any) => {

    /*=============================
    HIDE TOASTS pc="toast-discount-ok" and pc="toast-discount-error"
    =============================*/
    hideToasts();


    const coupon_form = document.querySelector('[pc="discount-form"]');
    if (coupon_form) {
        //get coupon input
        const coupon_input = coupon_form.querySelector('input');
        //get button
        const coupon_button = coupon_form.querySelector('[pc="discount-submit"]');

        //add event listener to the button
        coupon_button?.addEventListener("click", async (e) => {
            hideToasts();
            e.preventDefault();
            if (coupon_input) {
                const coupon = coupon_input.value;
                console.log(coupon)
                if (coupon !== "") {
                    ShowLoader(true);
                    await ApiRequest({
                        method: 'POST',
                        url: '/orders/addCoupon',
                        body: { 
                            coupon,
                            order_id: localStorage.getItem("order_id")
                        },
                        setResponse: (response: any) => {
                            showToast(response.status, coupon);
                            if (response.status === "success") {
                                coupon_form.classList.add('hidden');
                                getSummary();
                            }
                        }
                    });
                    ShowLoader(false);
                }
            }

        });
    }
   
};

/*=============================
HIDE COUPON CONTAINER
=============================*/
const hideCouponContainer = (coupon_id: any) => {
    
    console.log(coupon_id);
    const coupon_form = document.querySelector('[pc="discount-wrapper"]');
    if (coupon_form && coupon_id != 0) {
        coupon_form.classList.add('hidden');
    }
}


/*=============================
HIDE TOASTS
=============================*/
const hideToasts = () => {
    const toast_discount_ok = document.querySelector('[pc="toast-discount-ok"]');
    const toast_discount_error = document.querySelector('[pc="toast-discount-error"]');
    if (toast_discount_ok) {
        toast_discount_ok.classList.add('hidden');
    }
    if (toast_discount_error) {
        toast_discount_error.classList.add('hidden');
    }
}


const showToast = (type: string, coupon: string) => {
    const toast_discount_ok = document.querySelector('[pc="toast-discount-ok"]');
    const toast_discount_error = document.querySelector('[pc="toast-discount-error"]');
    if (type === "success") {
        if (toast_discount_ok) {
            toast_discount_ok.classList.remove('hidden');
        }
    } else {
        if (toast_discount_error) {
            toast_discount_error.classList.remove('hidden');
        }
    }
    /*=============================
    ADD INPUT VALUE TO TOAST toast-discount-code
    =============================*/
    if (type === "success") {
        const toast_container = document.querySelector('[pc="toast-discount-ok"]');
        const toast_discount_code = toast_container?.querySelector('[pc="toast-discount-code"]');
        if (toast_discount_code) {
            toast_discount_code.innerHTML = coupon;
        }
        
    }
    else{
        const toast_container = document.querySelector('[pc="toast-discount-error"]');
        const toast_discount_code = toast_container?.querySelector('[pc="toast-discount-code"]');
        if (toast_discount_code) {
            toast_discount_code.innerHTML = coupon;
        }
    }
}