import { ApiRequest, ShowLoader } from "GlobalFunctions";
import {getProductQuantity} from "components/Bag/Bag";
import { useEffect, useState } from "react";


export default function ProductList(props: any) {

    const {
        page,
        getSummary = () => {},
        onProductsLoaded // Added prop
    } = props;

    const [products, setProducts] = useState<any>(null);

    const loadData = async () => {
        console.log('loading data', page)
        const order_id = localStorage.getItem('order_id');

        await ApiRequest({
            url: "/orders/products",
            method: "get",
            query: {
                order_id,
                page
            },
            setResponse: async (response: any) => {
                console.log(response)
                if (response.status !== 'error') {
                    setProducts(response);
                    if (onProductsLoaded) { // Invoke callback
                        onProductsLoaded();
                    }
                }
                
            },
        });
    } 

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if(products){
            renderCartProducts(products);
        }
    }, [products]);

    const renderCartProducts = async (products: any) => {
    
        const cartProductsContainer = document.querySelector('[pc="product-list"]');
        if (!cartProductsContainer) return;
    
        const productModel = document.querySelector('[pc="product-item"]');
        if (!productModel) return;
        const productModelCloneForRemoval = productModel.cloneNode(true) as HTMLElement;
    
        cartProductsContainer.innerHTML = '';
    
    
        /* MAP PRODUCTS
        ==============================*/
        products.forEach((product: any) => {
            const productClone = productModelCloneForRemoval.cloneNode(true) as HTMLElement;
    
            productClone.querySelector('[pc="product-name"]')!.innerHTML = product.product_name;
            productClone.querySelector('[pc="product-price"]')!.innerHTML = '$' + Number(product.product_price).toFixed(2).toLocaleString();
            
            /* QUANTITY SELECT
            ==============================*/
            const quantityOptions = Array.from({ length: 10 }, (_, i) => i + 1).map(number => (
                `<option value="${number}">${number}</option>`
            )).join('');
    
            const quantitySelectElement = productClone.querySelector('[pc="product-quantity"]');
    
            if (quantitySelectElement) {
    
                const classes = quantitySelectElement?.getAttribute('class');
    
                if (quantitySelectElement.tagName.toLowerCase() !== 'select') {
                    const quantitySelect = `<select pc="product-quantity" class="${classes}" name="product-quantity">
                        ${quantityOptions}
                    </select>`;
                    quantitySelectElement.outerHTML = quantitySelect;
                }
    
                const updatedQuantitySelectElement = productClone.querySelector('[pc="product-quantity"]') as HTMLSelectElement;
                updatedQuantitySelectElement.innerHTML = quantityOptions;
                updatedQuantitySelectElement.value = String(product.quantity);
                updatedQuantitySelectElement.addEventListener('change', async () => {
                    await updateProductQuantity(product.order_product_id, updatedQuantitySelectElement.value);
                });
            } 
    
    
            /* IMAGE 
            ============================== */
            const productImageElement = productClone.querySelector('[pc="product-image"]') as HTMLImageElement;
    
            if (productImageElement) {
                productClone.querySelector('[pc="product-image"]')!.setAttribute('src', product.   product_image);
                productClone.querySelector('[pc="product-image"]')!.removeAttribute('srcset');
            }
    
            /* CUSTOM INPUTS
            ============================== */
            const custom_inputs = product.custom_inputs
                .map((attr: any) => `${attr.name}: ${attr.value}`)
                .join('<br>');
    
            const customInputsElement = productClone.querySelector('[pc="product-custom"]')
    
            if (customInputsElement) {
                customInputsElement.innerHTML = custom_inputs;
            }
    
            
    
            /* REMOVE BUTTON
            ============================== */
            const removeButtonElement = productClone.querySelector('[pc="remove-product"]') as HTMLElement;
    
            if (removeButtonElement) {
                removeButtonElement.addEventListener('click', async () => {
                    await removeProduct(product.order_product_id);
                });
            }
            
    
    
    
            /* CHILD PRODUCT
            ============================== */
            if (product.parent_product_id > 0) {
                productClone.classList.add('product-child');
            }
            
            
            cartProductsContainer.appendChild(productClone);
        });
    };



    /*=============================
    UPDATE PRODUCT QUANTITY
    =============================*/
    const updateProductQuantity = (order_product_id: string, quantity: string) => {
        
        ShowLoader(true);
        ApiRequest({
            url: "/orders/updateProductQuantity",
            method: "post",
            body: {
                order_product_id,
                quantity
            },
            setResponse: async (response: any) => {
                if (response.status === 'success') {
                    getSummary();
                    loadData();
                }
            },
        });
        ShowLoader(false);
    }

    /*=============================
    REMOVE PRODUCT
    =============================*/
    const removeProduct = async (order_product_id: string) => {
            
        ShowLoader(true);
        ApiRequest({
            url: "/orders/removeProduct",
            method: "post",
            body: {
                order_product_id,
                order_id: localStorage.getItem('order_id')
            },
            setResponse: async (response: any) => {
                if (response.status === 'success') {
                    const currentQuantity = response.currentQuantity;
                    if (currentQuantity === 0) {
                        localStorage.removeItem('order_id');
                        window.location.href = '/';
                    }
                    getProductQuantity();
                    getSummary();
                    loadData();
                }
            },
        });
        ShowLoader(false);
    }

    return null
}