import { ApiRequest, ShowLoader } from 'GlobalFunctions';
import { useEffect, useState } from 'react';
import getOrderDetails from '../Orders/getOrderDetails';
import Coupons from '../Orders/Coupons';
import Summary from 'components/Checkout/Summary';
import ProductList from 'components/Checkout/ProductList';

export default function Cart() {

    const [cart, setCart] = useState<any>(null);
    // useEffect(() => {
    //     getCart()
    // }, []);

    const [summary, setSummary] = useState<any>({});

    /*=========================================================
    getSummary
    =========================================================*/
    const getSummary = async () => {

        ApiRequest({
            url: "/orders/summary",
            method: "get",
            query: {
                order_id: localStorage.getItem('order_id')
            },
            setResponse: (response: any) => {
                setSummary(response);
                ShowLoader(false);
            }
        });
    }

    useEffect(() => {
        const checkout_button = document.querySelector('[pc="view-checkout"]');
        if (checkout_button) {
            checkout_button.addEventListener("click", async (e) => {
                window.location.href = "/pennicart-checkout";
            }
            )
        }
    }, []);

    const handleProductsLoaded = () => {
        getSummary();
    };



    return (
        <>
            <ProductList 
                page="cart" 
                getSummary={getSummary}
                onProductsLoaded={handleProductsLoaded} // Added prop
            />

            <Coupons
                summary={summary}
                page="cart"
                getSummary={getSummary}
            />

            <Summary summary={summary} getSummary={getSummary} />
        </>
    )
}

