import getOrderDetails from "components/Orders/getOrderDetails";
import { ApiRequest } from "GlobalFunctions";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";


export default function Shipping(props: any) {

    const [shippingMethods, setShippingMethods] = useState<any[]>([]);
    const [shippingModel, setShippingModel] = useState<any>(null);
    
    const {
        setShippingCompleted = () => {},
        getPaymentForm = () => {},
        getSummary = () => {},
        formValues,
    } = props;


    useEffect(() => {
        getShippingMethods();
    }, [props.forceUpdate]);
    


    /*=========================================================
    GET SHIPPING METHODS
    =========================================================*/
    const getShippingMethods = async () => {
        
        await ApiRequest({
            url: '/shipping/zones/getStoreShipping',
            method: 'GET',
            query: {
                order_id: localStorage.getItem('order_id')
            },
            setResponse: (response: any) => {
                setShippingMethods(response);
                if (response.length === 0) {
                    // enqueueSnackbar('No shipping methods available', { variant: 'error' });
                }
                
            }
        });
    }
    
    useEffect(() => {
        mapShippingMethods();
    }, [shippingMethods]);

    useEffect(() => {
        if (shippingMethods.length > 0) {
            selectActiveShipping();
        }
    }, [shippingMethods, formValues.shipping_id]);



    /*=========================================================
    MAP SHIPPING METHODS
    =========================================================*/
    const mapShippingMethods = () => {
        const container = document.querySelector('[pc="shipping-wrapper"]');
        const shippingModelHtml = document.querySelector('[pc="shipping-item"]');

        if ((shippingModelHtml || shippingModel) && container) {
            
            /*ONLY IF SHIPPING MODEL IS NULL CREATE A NEW ONE
            =========================================================*/
            if(shippingModel === null) {
                const clonedShippingModel = shippingModelHtml?.cloneNode(true) as HTMLElement;
                clonedShippingModel.classList.remove('is-active');
                setShippingModel(clonedShippingModel);
            }

            container.innerHTML = '';



            /* MAP SHIPPING METHODS
            =========================================================*/
            for (let method of shippingMethods) {
                console.log("method", method);
                let shippingMethod = shippingModel.cloneNode(true) as HTMLElement;

                /*LABEL
                =========================================================*/
                const shippingLabel = shippingMethod.querySelector('[pc="shipping-label"]') as HTMLElement;
                if (shippingLabel) {
                    shippingLabel.textContent = method.rate_name; 
                }

                /*RADIO BUTTON
                =========================================================*/
                const shippingRadio = shippingMethod.querySelector('[pc="shipping-radio"]') as HTMLInputElement;
                if (shippingRadio) {
                    shippingRadio.setAttribute('value', method.shipping_id.toString());
                }

                /*PRICE
                =========================================================*/
                const price = method.price === 0 ? 'Free' : '$' + parseFloat(method.price).toLocaleString();
                const shippingPrice = shippingMethod.querySelector('[pc="shipping-price"]') as HTMLElement;
                if (shippingPrice) {
                    shippingPrice.textContent = price;
                }

                /*LISTENERS
                =========================================================*/
                // El listener se añade después de preseleccionar el método
                shippingRadio.addEventListener('change', async () => {
                    // Agregar clase activa al método de envío seleccionado
                    document.querySelectorAll('[pc="shipping-item"]').forEach((element) => {
                        element.classList.remove('is-active');
                    });
                    shippingMethod.classList.add('is-active');

                    // Actualizar el método de envío
                    await updateShippingMethod(method.shipping_id, method.price);
                    setShippingCompleted(true);
                    
                });

                container.appendChild(shippingMethod);
            }

            if (shippingMethods.length === 0) {
                container.innerHTML = 'No shipping methods available';
    
            }

        }  

        
    }

    const updateShippingMethod = async (shipping_id:any, shipping_price: any) => {
        await ApiRequest({
            url: '/orders/updateShippingMethod',
            method: 'POST',
            body: {
                order_id: localStorage.getItem('order_id'),
                shipping_price,
                shipping_id
            },
            setResponse: (response: any) => {
                getSummary();
                 
            }
        });
    }

    const selectActiveShipping = () => {
        console.log("formValues.shipping_id", formValues.shipping_id);
        shippingMethods.forEach((method) => {
            const shippingRadio = document.querySelector(`[pc="shipping-radio"][value="${method.shipping_id}"]`) as HTMLInputElement;
            const shippingMethod = shippingRadio?.closest('[pc="shipping-item"]') as HTMLElement;
            if (shippingRadio && shippingMethod) {
                if (parseInt(method.shipping_id, 10) === parseInt(formValues.shipping_id, 10)) {
                    shippingRadio.checked = true;
                    shippingMethod.classList.add('is-active');
                    const wRadioInput = shippingMethod.querySelector('.w-radio-input') as HTMLElement;
                    if (wRadioInput) {
                        wRadioInput.classList.add('w--redirected-checked');
                    }
                    setShippingCompleted(true);
                }
            }
        }
        );
                            
        
        
    }

    


    return null
}





